import http from '@/utils/request'
// 获取列表
export const listReportDaily = (data) => http.post("/system/productReportDaily/listReportDaily", data)
//添加
export const addReportDaily = (data) => http.post("/system/productReportDaily/addReportDaily", data)
//日报折线图
export const trendOfReportDaily = (data) => http.post("/system/productReportDaily/trendOfReportDaily", data)
//导出
export const exportReportDaily = (data) => http.postquerys("/system/productReportDaily/exportReportDaily", data)

export const updateReportDaily = (data) => http.post("/system/productReportDaily/updateReportDaily", data)

// 周
// 列表查询
export const findReportWeekList = (data) => http.post("/system/sysReportWeek/findReportWeekList", data)
// 图表
export const findReportWeekChart = (data) => http.post("/system/sysReportWeek/findReportWeekChart", data)
//添加
export const addAndEditReportWeek = (data) => http.post("/system/sysReportWeek/addAndEditReportWeek", data)
// 月
// 列表
export const findReportMonthList = (data) => http.post("/system/sysReportMonth/findReportMonthList", data)
// 添加
export const addAndEditReportMonth = (data) => http.post("/system/sysReportMonth/addAndEditReportMonth", data)
// 折线图
export const findReportMonthChart = (data) => http.post("/system/sysReportMonth/findReportMonthChart", data)
// 图标到至今得数据
export const findReportMonthChartHitherto = (data) => http.post("/system/sysReportMonth/findReportMonthChartHitherto", data)
// 周自动计算
export const findProductAuto = (data) => http.post("/system/sysReportWeek/findProductAuto", data)
// 周报导出
export const exportReportWeekList = (data) => http.postquerys("/system/sysReportWeek/exportReportWeekList", data)
// 月报导出
export const exportReportMonthList = (data) => http.postquerys("/system/sysReportMonth/exportReportMonthList", data)
// 月报自动计算
export const sysReportMonth = (data) => http.post("/system/sysReportMonth/findProductAuto", data)