<template>
  <div class="dailyPaper">
    <div>
      <div class="flex" style="border-top: 1px solid #ccc; padding-top: 10px">
        <div class="title">煤炭产量趋势图</div>
        <div>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="pickChange"
          >
          </el-date-picker>
        </div>
      </div>
      <div>
        <div id="main" style="width: 100%; height: 400px"></div>
      </div>
      <div class="content">
        <div class="lines"></div>
        <div style="display: flex; justify-content: space-between">
          <div class="cont_title">生产煤矿日报数据录入</div>
          <div style="text-align: right; display: flex">
            <div style="margin-right: 10px">
              <el-date-picker
                v-model="value2"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="pickChanges"
              >
              </el-date-picker>
            </div>
            <el-button @click="addPrimary" type="primary">录入</el-button>
            <el-button @click="exportSubmit">
              <a href="javascript:void(0)" style="color: #333">导出</a>
            </el-button>
          </div>
        </div>

        <div style="margin-top: 20px">
          <el-table :data="tableData" style="width: 100%" border>
            <el-table-column label="操作" width="120px" align="center">
              <template slot-scope="scope">
                <el-button
                  :disabled="scope.row.auditStatus == 0"
                  @click.native.prevent="deleteRow(scope.row, tableData)"
                  type="text"
                  size="small"
                >
                  编辑
                </el-button>
                <el-button
                  :disabled="scope.row.auditStatus == null"
                  @click.native.prevent="modifyRecord(scope.row)"
                  type="text"
                  size="small"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="dailyDateStr" label="日期" align="center">
            </el-table-column>
            <el-table-column
              prop="dailyProduct"
              label="当日产量(万吨)"
              align="center"
            >
            </el-table-column>
            <!-- <el-table-column prop="dailySales" label="当月累计产量(万吨)" align="center">
                        </el-table-column> -->
            <el-table-column
              prop="dailySales"
              label="当日销量 (万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="dailyPrice"
              label="当日价格 (元/吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="stock" label="库存情况(万吨)" align="center">
            </el-table-column>
            <!-- <el-table-column prop="" label="外调煤铁路方向 (个)" align="center">
                        </el-table-column>
                        <el-table-column prop="" label="铁路当日合计运量(万吨)" align="center">
                        </el-table-column>
                        <el-table-column prop="" label="外调煤公路方向 (个)" align="center">
                        </el-table-column>
                        <el-table-column prop="" label="公路当日合计运量(万吨)" align="center">
                        </el-table-column> -->
            <el-table-column prop="remark" label="备注" align="center">
            </el-table-column>
            <el-table-column prop="remark" label="状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.auditStatus === null">正常</span>
                <span v-if="scope.row.auditStatus == 0">待审核</span>
                <span v-if="scope.row.auditStatus == 1">审核通过</span>
                <span v-if="scope.row.auditStatus == 2">审核拒绝</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="700px"
      destroy-on-close
      center
      style="min-width: 800px"
    >
      <div
        style="
          border-bottom: 1px solid #ccc;
          padding-bottom: 10px;
          text-align: center;
        "
      >
        <div style="font-size: 19px">日报数据录入</div>
      </div>
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <div class="flex" style="margin-top: 20px">
            <el-form-item label="日期" prop="dailyDate">
              <el-date-picker
                v-model="ruleForm.dailyDate"
                :picker-options="setDisabled"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="当日产量(万吨)" prop="dailyProduct">
              <el-input
                v-model="ruleForm.dailyProduct"
                placeholder="请输入当日产量"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <!-- 当月累计产量 -->
            <el-form-item label="当日销量(万吨)" prop="dailySales">
              <el-input
                v-model="ruleForm.dailySales"
                placeholder="请输入当日销量"
              ></el-input>
            </el-form-item>
            <el-form-item label="库存情况(万吨)" prop="stock">
              <el-input
                v-model="ruleForm.stock"
                placeholder="请输入库存情况"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <!-- 当月累计产量 -->
            <el-form-item label="当日价格(元/吨)" prop="dailyPrice">
              <el-input
                v-model="ruleForm.dailyPrice"
                placeholder="请输入当日价格"
              ></el-input>
              <div style="color: red">注:主要煤种平均价格(坑口含税)</div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Vue from "vue";
import citys from "@/assets/json/citys";
import * as types from "@/store/action-types";
import {
  listReportDaily,
  addReportDaily,
  trendOfReportDaily,
  exportReportDaily,
  updateReportDaily,
} from "@/api/mining.js";
export default {
  data() {
    return {
      setDisabled: {
        disabledDate(time) {
          return time.getTime() > Date.now(); // 可选历史天、可选当前天、不可选未来天
          // return time.getTime() > Date.now() - 8.64e7;  // 可选历史天、不可选当前天、不可选未来天
          // return time.getTime() < Date.now() - 8.64e7;  // 不可选历史天、可选当前天、可选未来天
          // return time.getTime() < Date.now(); // 不可选历史天、不可选当前天、可选未来天
        },
      },
      value2: "",
      citys: citys,
      list: [
        {
          accumulated: "",
          directionId: "",
          price: "",
          total: "",
        },
      ],
      list1: [
        {
          directionId: "",
          total: "",
          accumulated: "",
          price: "",
        },
      ],
      config: {
        pageNum: 1,
        pageSize: 10,
        searchEndTime: "",
        searchStartTime: "",
      },
      ruleForm: {
        dailyDate: "",
        dailyProduct: "",
        dailySales: "",
        stock: "",
        remark: "",
        dailyPrice: "",
      },
      rules: {
        dailyDate: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        dailyProduct: [
          { required: true, message: "请输入当日产量", trigger: "blur" },
        ],
        dailySales: [
          { required: true, message: "请输入当日累计销量", trigger: "blur" },
        ],
        dailyPrice: [
          { required: true, message: "请输入当日价格", trigger: "blur" },
        ],
        stock: [{ required: true, message: "请输入库存情况", trigger: "blur" }],
      },
      dialogVisible: false,
      tableData: [],
      value1: "",
      charts: "",
      opinionData: [],
      currentPage1: 1,
      total: 0,
      dailtConfig: {
        enterpriseId: Vue.ls.get(types.enterpriseId),
        searchEndTime: null,
        searchStartTime: null,
      },
      xdata: [],
    };
  },

  created() {
    this.getlist();
    this.getDaily();
  },

  methods: {
    modifyRecord(data) {
      this.$emit("getDetail", data.dailyId);
    },
    deteleList1() {
      if (this.list1.length > 1) {
        this.list1.pop();
      }
    },
    deteleList() {
      if (this.list.length > 1) {
        this.list.pop();
      }
    },
    addDomain() {
      let obj = {
        accumulated: "",
        directionId: "",
        price: "",
        total: "",
      };
      console.log(this.list);
      this.list.push(obj);
    },
    addDomains() {
      let _this = this;
      let obj = {
        directionId: "",
        total: "",
        accumulated: "",
        price: "",
        irectionName: "",
      };
      _this.list1.push(obj);
    },
    deleteRow(e, t) {
      console.log(e, t);
      if (e.auditStatus == 0) {
        return;
      }
      this.ruleForm = {};
      this.ruleForm = e;
      let obj = {
        directionId: "",
        total: "",
        accumulated: "",
        price: "",
        irectionName: "",
      };
      if (e.railway) {
        this.list1 = e.railway;
      } else {
        this.list1.push(obj);
      }
      if (e.highway) {
        this.list = e.highway;
      } else {
        let obj = {
          directionId: "",
          total: "",
          accumulated: "",
          price: "",
          irectionName: "",
        };
        this.list.push(obj);
      }
      // this.list1 = e.railway
      // this.list = e.highway
      this.ruleForm.dailyDate = e.dailyDateStr;
      this.dialogVisible = true;
    },
    exportSubmit() {
      exportReportDaily(this.config).then((res) => {
        var elink = document.createElement("a");
        elink.download = "日报数据.xls";
        elink.style.display = "none";
        var blob = new Blob([res], { type: "application/vnd.ms-excel" });
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      });
    },
    pickChange(e) {
      if (e) {
        this.dailtConfig.searchStartTime = e[0];
        this.dailtConfig.searchEndTime = e[1];
      } else {
        this.dailtConfig.searchStartTime = null;
        this.dailtConfig.searchEndTime = null;
      }
      this.getDaily();
    },
    pickChanges(e) {
      if (e) {
        this.config.searchStartTime = e[0];
        this.config.searchEndTime = e[1];
      } else {
        this.config.searchStartTime = null;
        this.config.searchEndTime = null;
      }
      this.getlist();
    },
    getDaily() {
      this.opinionData = [];
      this.xdata = [];
      trendOfReportDaily(this.dailtConfig).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((item) => {
            this.opinionData.push(item.value);
            this.xdata.push(item.time);
          });
          this.$nextTick(function () {
            this.drawLine("main");
          });
        }
      });
    },
    getlist() {
      this.config.enterpriseId = Vue.ls.get(types.enterpriseId);
      listReportDaily(this.config).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.total = res.data.total;
          console.log(
            this.tableData,
            "2222222222222222222222222222222222222222"
          );
        }
      });
    },
    addPrimary() {
      this.ruleForm = {};
      this.dialogVisible = true;
    },
    handleSizeChange(e) {
      console.log(e, "111111111");
    },
    addForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.list1) {
            this.list1.forEach((item) => {
              console.log();
              if (item.directionId instanceof Array) {
                item.directionId = item.directionId[1];
              }
            });
          }
          if (this.list) {
            this.list.forEach((item) => {
              if (item.directionId instanceof Array) {
                item.directionId = item.directionId[1];
              }
            });
          }

          this.ruleForm.railway = this.list1;
          this.ruleForm.highway = this.list;
          let enterpriseId = Vue.ls.get(types.enterpriseId);
          this.ruleForm.enterpriseId = enterpriseId;
          if (this.ruleForm.dailyId) {
            updateReportDaily(this.ruleForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.getlist();
                this.dialogVisible = false;
                this.ruleForm = {};
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            addReportDaily(this.ruleForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "录入成功",
                  type: "success",
                });
                this.getlist();
                this.getDaily();
                this.dialogVisible = false;
                this.ruleForm = {};
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
    handleCurrentChange(e) {
      this.config.pageNum = e;
      this.getlist();
    },
    // 绘制柱状图
    drawLine(id) {
      this.charts = echarts.init(document.getElementById(id));
      this.charts.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          backgroundColor: "rgba(0, 0, 0,0.5)",
          borderColor: "#1198B9",
          textStyle: {
            fontSize: 14,
            color: "#FFFFFF",
          },
          formatter: function (params) {
            let relVal = null;
            for (let i = 0; i < params.length; i++) {
              relVal +=
                "<br/>" +
                params[i].seriesName +
                ":  " +
                params[i].value +
                "吨;";
            }
            return relVal.slice(9);
          },
        },
        legend: {
          show: false,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        // toolbox: {
        //     feature: {
        //         saveAsImage: {},
        //     },
        // },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xdata,
        },
        yAxis: {
          type: "value",
          name: "万吨",
        },

        series: [
          {
            name: "煤炭产量",
            type: "line",
            stack: "总量",
            data: this.opinionData,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dailyPaper {
  padding: 20px;
}

.flexs {
  display: flex;
  /* justify-content: space-around; */
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 23px;
}

.content {
  margin-top: 20px;
}

.cont_title {
  text-align: left;
  font-size: 23px;
}

::v-deep .el-date-editor .el-range-separator {
  width: 10%;
}

.lines {
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 10px 0;
}
</style>
